@import "~antd/dist/antd.css";

.ant-btn-round:hover {
  background: #f0f0f0 !important;
  border-color: #cd6091 !important;
  color: #cd6091 !important;
}

.ant-btn-round {
  margin-left: 40px !important;
  color: #f0f0f0 !important;
  padding-left: 100px !important;
  padding-right: 100px !important;
  background: #cd6091 !important;
  border: 2px solid #f3f3f3 !important;
}

.marketplace-list-my-badges-button {
  color: #f0f0f0 !important;
  padding: 10px !important;
  background: #cd6091 !important;
  border: 2px solid #f3f3f3 !important;
}

.marketplace-list-my-badges-button:hover {
  background: #f0f0f0 !important;
  border-color: #cd6091 !important;
  color: #cd6091 !important;
}

.text-green-dark {
  color: #cd6091;
}

.link a {
  color: #000000;
}

.link a:hover {
  color: #cd6091;
}

.menu a:hover {
  color: #cd6091;
}

.dropdown-item {
  background: #cd6091 !important;
  color: #f0f0f0 !important;
}

.dropdown-menu a:hover {
  background: #cd6091 !important;
  color: #f0f0f0 !important;
}

.dropdown-menu a {
  color: #000000 !important;
}

.border-pink {
  --tw-border-opacity: 1;
  border-color: rgb(208 144 144 / var(--tw-border-opacity));
}

.text-pink {
  color: #cd6091;
}

.center {
  text-align: center;
}

.ant-btn-list {
  color: #f0f0f0 !important;
  padding-left: 100px !important;
  padding-right: 100px !important;
  background: #cd6091 !important;
  border: 2px solid #f3f3f3 !important;
}
